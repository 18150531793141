(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/middleware-authenticated/assets/authenticated.js') >= 0) return;  svs.modules.push('/components/payment/middleware-authenticated/assets/authenticated.js');
'use strict';

const {
  MiddlewarePaymentError
} = svs.components.payment.common.error;
const {
  userSession,
  urlMapping
} = svs.core;
const {
  PLAYER,
  RETAILER
} = userSession.roles;
const convertParamsToObject = params => {
  const searchParams = {};
  for (const [key, value] of params.entries()) {
    searchParams[key] = value;
  }
  return searchParams;
};
const getReturnUrl = _ref => {
  let {
    extraParams
  } = _ref;
  const returnQuery = extraParams instanceof URLSearchParams ? convertParamsToObject(extraParams) : extraParams;
  const queryParams = Object.keys(returnQuery).reduce((acc, key, index) => {
    if (typeof returnQuery[key] !== 'undefined') {
      if (index === 0) {
        return "".concat(key, "=").concat(returnQuery[key]);
      }

      return "".concat(acc, "&").concat(key, "=").concat(returnQuery[key]);
    }
    return acc;
  }, '');
  return "".concat(window.location.pathname, "?").concat(encodeURIComponent(queryParams));
};
const authenticated = _ref2 => {
  let {
    userContext
  } = _ref2;
  return next => {
    try {
      if (!userSession.hasRole(PLAYER) && !userSession.hasRole(RETAILER)) {
        let returnUrl = window.location.href;
        if (userContext.returnQuery) {
          returnUrl = svs.components.payment.middleware.authenticated.getReturnUrl({
            extraParams: userContext.returnQuery
          });
        }
        const loginHome = urlMapping.get('loginHome');
        if (loginHome) {
          window.location.href = "".concat(loginHome, "?returnUrl=").concat(returnUrl);
        }
        return next(new MiddlewarePaymentError({
          clientCode: MiddlewarePaymentError.ErrorCodes.NOT_AUTHENTICATED
        }));
      }
    } catch (error) {
      return next(new MiddlewarePaymentError({
        clientCode: MiddlewarePaymentError.ErrorCodes.NOT_AUTHENTICATED
      }));
    }
    next();
  };
};
setGlobal('svs.components.payment.middleware.authenticated', {
  authenticated,
  getReturnUrl
});

 })(window);